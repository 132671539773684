<template >
  <div>
    <section class="py-4 osahan-main-body">
      <div class="container">
        <div class="row">
          <!-- order status list -->
          <div class="col-md-3 mb-3">
            <ul
              class="
                nav nav-tabsa
                custom-tabsa
                border-0
                flex-column
                bg-white
                rounded
                overflow-hidden
                shadow-sm
                p-2
                c-t-order
              "
              id="myTab"
              role="tablist"
            >
              <li
                v-for="(tab, index) in orderTypes"
                v-bind:key="index"
                class="nav-item"
                role="presentation"
                v-on:click="dtlShow = false"
              >
                <a
                  :class="
                    'nav-link border-0 text-dark py-3  initCap ' + tab.active
                  "
                  :id="tab.type + '-tab'"
                  data-toggle="tab"
                  :href="'#' + tab.type"
                  role="tab"
                  :aria-controls="tab.type"
                  :aria-selected="tab.selected"
                >
                  <i :class="'mr-2 mb-0 ' + tab.exClass"></i>
                  {{ tab.type.replace("-", " ") }}</a
                >
              </li>
            </ul>
          </div>
          <!-- orders list -->
          <div
            v-show="!dtlShow"
            v-if="orders"
            class="tab-content col-md-9"
            id="myTabContent"
          >
            <div
              v-for="(tab, index) in orderTypes"
              v-bind:key="index"
              :class="
                'tab-pane fade ' +
                tab.active +
                (tab.active == 'active' ? ' show' : '')
              "
              :id="tab.type"
              role="tabpanel"
              :aria-labelledby="tab.type + '-tab'"
            >
              <div class="order-body">
                <div
                  v-for="(order, index) in orders.filter(
                    (c) => tab.statusList.indexOf(c.status) != -1
                  )"
                  v-bind:key="index"
                  class="pb-3"
                >
                  <div class="p-3 rounded shadow-sm bg-white">
                    <div class="d-flex border-bottom pb-3">
                      <div class="text-muted mr-3">
                        <img
                          alt="#"
                          src="img/meal.jpg"
                          class="
                            img-fluid
                            btn btn-outline-light
                            shadow-sm
                            order_img
                            rounded
                          "
                        />
                      </div>

                      <div>
                        <p>
                          ORDER
                          <span class="text-info">{{ order.order_id }}</span>
                          <br />
                          <span
                            class="mb-0 small"
                            v-if="addressList.length > 0"
                          >
                            <span v-if="order.address">
                              <span
                                v-if="
                                  addressList.filter(
                                    (f) => f.id == parseInt(order.address)
                                  )[0] != undefined
                                "
                              >
                                {{
                                  addressList.filter(
                                    (f) => f.id == parseInt(order.address)
                                  )[0].address1
                                }}</span
                              ></span
                            >
                          </span>
                        </p>

                        <p class="mb-0 small">
                          <a
                            class="btn btn-outline-primary btn=sm small"
                            v-on:click="showDetails(order.order_id)"
                            >View Details</a
                          >
                        </p>
                      </div>
                      <div class="ml-auto">
                        <p
                          :class="
                            'text-white text-center py-1 px-2 rounded small mb-1 ' +
                            statusColor(order.status)
                          "
                        >
                          {{ order.status }}
                        </p>

                        <p class="small font-weight-bold text-center">
                          <i class="feather-clock"></i> {{ order.created_at }}
                        </p>
                        <div class="text-muted m-0 ml-auto mr-3 small">
                          {{ order.orders.split(",").length }} Item(s)<br />
                        </div>
                        <div class="text-muted m-0 ml-auto mr-3 small">
                          Total Payment<br />
                          <span class="text-dark font-weight-bold">{{
                            order.total
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex pt-3">
                      <div class="small">
                        <div v-show="tab.type == 'canceled'">
                          Reason ({{ order.rejected_for }})
                        </div>
                      </div>
                      <div class="text-right w-100">
                        <!-- <a
                          v-show="tab.type == 'progress'"
                          v-on:click="showDetails(order)"
                          class="btn btn-primary px-3"
                          >Track</a
                        > -->
                        <a
                          href="contact-us"
                          class="btn btn-outline-primary px-3"
                          >Help</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="
                    orders.filter((c) => tab.statusList.indexOf(c.status) != -1)
                      .length == 0
                  "
                  class="
                    text-center
                    font-weight-bold
                    p-3
                    rounded
                    shadow-sm
                    bg-white
                  "
                >
                  No Orders Found
                </div>
              </div>
            </div>
          </div>
          <!-- details -->
          <div class="tab-content col-md-9" v-show="!detailedOrder&&dtlShow">
            <p class="h3 text-center mt-5">Loading Order's Details</p>
          </div>
          <div
            v-show="dtlShow"
            v-if="detailedOrder"
            class="tab-content col-md-9"
            id="myTabContent"
          >
            <section
              class="
                bg-white
                osahan-main-body
                rounded
                shadow-sm
                overflow-hidden
              "
            >
              <div class="container p-0">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="osahan-status">
                      <!-- status complete -->
                      <div class="p-3 status-order border-bottom bg-white">
                        <p class="small m-0">
                          <i class="feather-calendar text-primary"></i
                          >{{ detailedOrder.created_at }}
                        </p>
                        <span class="ml-auto"
                          ><a
                            href="#"
                            v-on:click="dtlShow = false"
                            class="btn btn-sm btn-primary float-right"
                            style="margin-top: -23px !important"
                            >X</a
                          ></span
                        >
                      </div>
                      <div class="p-3 border-bottom">
                        <div class="d-flex">
                          <h6 class="font-weight-bold">Order Status</h6>
                        </div>
                        <div class="tracking-wrap">
                          <div
                            v-show="
                              detailedOrder.status != 'Canceled' &&
                              detailedOrder.status != 'Rejected'
                            "
                            v-for="(status, index) in statusList"
                            v-bind:key="index"
                            class="my-1 step active"
                          >
                            <span
                              :class="
                                status.status.indexOf(detailedOrder.status) !=
                                -1
                                  ? 'icon m-1 text-success'
                                  : 'm-1 icon text-danger'
                              "
                              ><i
                                :class="
                                  status.status.indexOf(detailedOrder.status) !=
                                  -1
                                    ? 'feather-check-circle'
                                    : 'feather-x-circle'
                                "
                              ></i
                            ></span>
                            <span class="text small">{{ status.text }}</span>
                          </div>
                          <div
                            v-show="
                              detailedOrder.status == 'Canceled' ||
                              detailedOrder.status == 'Rejected'
                            "
                          >
                            <div class="my-1 step active">
                              <span class="m-1 icon text-danger"
                                ><i class="feather-x-circle">Pending</i></span
                              >
                            </div>
                            <div class="my-1 step active">
                              <span class="icon m-1 text-success"
                                ><i class="feather-check-circle">{{
                                  detailedOrder.status
                                }}</i></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Destination -->
                      <div class="p-3 border-bottom bg-white">
                        <h6 class="font-weight-bold">Destination</h6>
                        <p class="m-0 small">
                          <span v-if="detailedOrder.delivery_type == 2"
                            >Pickup</span
                          >
                          <span v-if="detailedOrder.address">
                            <i class=""></i>
                            {{ detailedOrder.address[0].address1 }}</span
                          >
                          <span
                            v-if="detailedOrder.area"
                            v-show="detailedOrder.area.area_name_en != ''"
                            >/ {{ detailedOrder.area.area_name_en }}</span
                          >
                        </p>
                        <a
                          v-show="
                            detailedOrder.status == 'Delivered' ||
                            detailedOrder.status == 'Canceled' ||
                            detailedOrder.status == 'Rejected'
                          "
                          style="margin-top: -50px !important"
                          v-on:click="reOrder"
                          class="btn btn-primary px-3 float-right text-white"
                          >Reorder</a
                        >
                      </div>
                      <!-- <div class="p-3 border-bottom">
                        <p class="font-weight-bold small mb-1">Courier</p>
                        <img
                          alt="#"
                          src="img/logo_web.png"
                          class="img-fluid sc-osahan-logo mr-2"
                        />
                        <span class="small text-primary font-weight-bold"
                          >Swiggiweb Courier
                        </span>
                      </div> -->
                      <!-- total price -->
                      <!-- Destination -->
                      <div class="p-3 border-bottom bg-white pb-1">
                        <div class="w-100 my-2 d-block">
                          <div
                            class="row w-100 m-1 d-block border-bottom"
                            style="min-height: 57px"
                            v-for="item in detailedOrder.items"
                            :key="item.id"
                          >
                            <img
                              class="
                                img-fluid
                                rounded-pill
                                float-left
                                my-0
                                mx-0
                                mr-3
                              "
                              style="width: 60px; height: 40px"
                              :src="
                                $store.state.baseURL + '/' + item.info[0].image
                              "
                            />
                            <p
                              class="d-d-block m-1 col-10 px-2"
                              style="height: 40px; line-height: 40px"
                            >
                              {{ item.name.name_en }}
                            </p>
                            <div>
                              <p class="text-info small m-1">
                                {{ item.info[0].size_en }}
                              </p>
                              <span
                                class="
                                  ml-auto
                                  text-info
                                  float-sm-right
                                  mb-1
                                  small
                                "
                                style="margin-top: -20px !important"
                                >{{
                                  Math.round(
                                    parseFloat(item.total_price) * 100
                                  ) / 100
                                }}</span
                              >
                            </div>
                            <div
                              class="small m-1"
                              v-for="ex in item.extras"
                              :key="ex.id"
                            >
                              <i class="feather-arrow-right mr-1"></i
                              >{{ ex.name_en }}
                              <span class="float-right">{{ ex.price_en }}</span>
                            </div>
                            <div
                              class="small m-1"
                              v-for="opt in item.options"
                              :key="opt.id"
                              :style="
                                parseFloat(opt.price_en) == 0
                                  ? 'color:green'
                                  : ''
                              "
                            >
                              <i class="feather-arrow-right mr-1"></i
                              >{{ opt.name_en }}
                              <span class="float-right">{{
                                parseFloat(opt.price_en) == 0
                                  ? "FREE"
                                  : parseFloat(opt.price_en).toFixed(2)
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <p class="align-items-center m-1 row">
                            <span class="mb-1"> Subtotal </span>
                            <span class="ml-auto mb-1">
                              {{ detailedOrder.sub_total }}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p class="align-items-center m-1 row">
                            <span class="mb-1"> Discount </span>
                            <span class="ml-auto mb-1">
                              {{ detailedOrder.discount }}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p class="align-items-center m-1 row">
                            <span class="mb-1"> VAT </span>
                            <span class="ml-auto mb-1">
                              {{ detailedOrder.tax_fees }}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p class="align-items-center m-1 row">
                            <span class="mb-1"> Delivery Fees </span>
                            <span class="ml-auto mb-1">
                              {{
                                parseFloat(detailedOrder.delivery_fees) == 0
                                  ? "free"
                                  : parseFloat(
                                      detailedOrder.delivery_fees
                                    ).toFixed(2)
                              }}
                            </span>
                          </p>
                        </div>
                        <div class="border-top px-2 pt-3 pb-0">
                          <div class="align-items-center mb-2 row">
                            <h6 class="font-weight-bold mb-1">Total Cost</h6>
                            <h6 class="font-weight-bold ml-auto mb-1">
                              {{ detailedOrder.total }} L.E.
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="m-0 d-block small text-muted w-100 my-1 p-3">
                        You can check your order detail here, Thank you for
                        order.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <loading :isLoading="isLoading" />
  </div>
</template>
<script>
import loading from "../components/loading.vue";
export default {
  components: { loading },
  data() {
    return {
      orders: null,
      dtlShow: false,
      orderTypes: [
        {
          type: "progress",
          exClass: "feather-clock text-warning",
          selected: "true",
          text: "On Progress",
          active: "active",

          statusList: "New,Pending,ProcessingIn-way",
        },
        {
          type: "completed",
          exClass: "feather-check text-success",
          selected: "false",
          text: "Completed",
          active: "",
          statusList: "Delivered",
        },
        {
          type: "canceled",
          exClass: "feather-x-circle txt-danger",
          selected: "false",
          text: "Canceled",
          active: "",
          statusList: "Canceled,Rejected",
        },
      ],
      statusList: [
        { status: "New,Pending", text: "Pending" },
        { status: "Processing", text: "Preparing Order" },
        { status: "In-way", text: "In Way" },
        { status: "Delivered,Canceled,Rejected", text: "Delivered" },
      ],
      detailedOrder: null,
      dtlOrderCity: null,
      isLoading: true,
    };
  },
  computed: {
    addressList() {
      let list = null;
      list = JSON.parse(this.$store.state.curUser).address;
      return list;
    },
  },
  methods: {
    showDetails(id) {
      this.detailedOrder = null;
      this.dtlShow = true;
      fetch(
        this.$store.state.baseURL +
          "/api/order/details/" +
          id +
          "?api_token=" +
          window.localStorage.getItem("jwt"),
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          if (response.response) {
            this.result = response.data;
            this.detailedOrder = JSON.parse(
              JSON.stringify(this.result)
            ).order[0];
          } else {
            if (response.message) console.log(response.message);
            else if (response.messages) console.log(response.messages);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    statusColor(status) {
      let color = "";
      switch (status) {
        case "Rejected":
        case "Canceled":
          color = "bg-danger";
          break;
        case "New":
          color = "bg-success";
          break;
        case "Pending":
          color = "bg-warning";
          break;
        case "Processing":
        case "In-way":
          color = "bg-info";
          break;
        case "Delivered":
          color = "bg-secondary";
          break;
        default:
          break;
      }
      return color;
    },
    reOrder() {
      let CurOrder = JSON.parse(window.localStorage.getItem("curOrder"));
      if (!CurOrder) {
        CurOrder = { address: null, items: [], notes: null };
      }
      CurOrder.items = [];

      this.detailedOrder.items.forEach((itm, index) => {
        let item = {
          id: index + 1,
          name_en: itm.name.name_en,
          size_en: itm.info[0].size_en,
          count: itm.count,
          price: parseFloat(itm.total_price),
          total:
            parseFloat(itm.total_price) +
            parseFloat(itm.total_extras_price) +
            parseFloat(itm.total_options_price),
          menu_item_id: itm.item,
          options: itm.options,
          extras: itm.extras,
          special: itm.special,
        };
        CurOrder.items.push(item);
      });
      CurOrder.notes = this.detailedOrder.notes;
      window.localStorage.setItem("curOrder", JSON.stringify(CurOrder));
      window.location = "/home";
    },
  },
  mounted() {
    this.detailedOrder=null
    fetch(
      this.$store.state.baseURL +
        `/api/user/history?api_token=${window.localStorage.getItem("jwt")}`,
      { method: "GET" }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log(
            "Server returned " + response.status + " : " + response.statusText
          );
        }
      })
      .then((response) => {
        if (response.response && response.data) {
          this.orders = response.data.details;
        } else {
          console.log(response.response);
          if (response.message) {
            if (response.message == "Invalid Token") {
              alert(
                "you might logged in with another device or browser, please login again."
              );
              window.location = "/login";
            } else {
              console.log(response.message);
            }
          }
          //if(response.messages)console.log(response.messages)
        }
      });
  },
  updated() {
    this.isLoading = false;
  },
};
</script>
<style>
.btn {
  margin: 2px 3px !important;
}
.initCap {
  text-transform: capitalize;
}
</style>